/* モーダルそのものの表示設定 */
.settings-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

/* モーダルのコンテンツ */
.settings-modal-content {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90vw;
    text-align: left;
    max-height:70vh;
    overflow-y:auto;
}

.settings-modal-title {
    border-bottom: solid;
}

.settings-modal-dropdown{
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
}

.settings-modal-textinput{
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
}

.settings-modal-checkbox{
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap; /* 改行を防ぐ */
}

.settings-modal-color{
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
}

.settings-modal-colorpick{
    height: 30px;
    width: 30px;
    padding: 0;
}

.settings-modal-content > .settings-modal-textinput > input{
    width:auto;
}

.modal-actions > .button-del-l{
    background-color:#e74c3c;
    margin-right: auto;
}

.modal-actions > .button-del-l:hover{
    background-color:#c0392b;
}
