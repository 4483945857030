/* モーダルのスタイル */
/* モーダルの背景オーバーレイ */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  /* モーダルのコンテンツ */
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90vw;
    text-align: left;
  }

  .modal-content > h3{
    border-bottom:solid black;
  }

  .shift-selection {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }


.modal-content .dropdowns-inline {
  display: flex;
  gap: 10px; /* セット間の余白 */
  align-items: center;
  justify-content: space-between
}

.modal-content .dropdown-inline {
  display: flex;
  gap: 10px; /* セット間の余白 */
  align-items: center;
  justify-content: space-between
}

.modal-content .radio-buttons-inline {
  display: flex;
  gap: 10px; /* セット間の余白 */
  align-items: center;
}

.modal-content .checkbox-label{
  display: flex;
  white-space: nowrap;
  justify-content: space-between;
}

.modal-content .checkbox-label input{
  width:auto;
}

.modal-content .checkbox-inline {
  width:auto;
  display: inline-flex; /* ラベルとラジオボタンを横並びに */
  align-items: center; /* ボタンと文字を垂直方向で揃える */
  white-space: nowrap; /* 改行を防ぐ */
  gap: 5px; /* 文字とボタンの間隔を調整 */
  flex-direction: row;
  justify-content: space-between;
}

.modal-content .color-label{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-content .color-select{
    height: 30px;
    width: 30px;
    padding: 0;
}

.modal-content .timeinput{
  width:auto;
}

  .special-settings {
    display: grid;
    gap: 10px;
    margin-top: 10px;
  }
  
  textarea {
    width: 100%;
    margin-top: 10px;
    resize: none;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .modal-actions {
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
    gap: 2px;
    margin-top: 20px;
  }
  
  .modal-actions button {
    padding: 8px;
    margin:2px;
    border: none;
    background-color: #3498db;
    color: white;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .modal-actions button:hover {
    background-color: #2980b9;
  }

  .modal-actions > .button-del-l{
    background-color:#e74c3c;
    margin-right: auto;
}

.modal-actions > .button-del-l:hover{
    background-color:#c0392b;
}

.modal-body.disabled{
  pointer-events: none; /* ユーザー操作を無効化 */
  opacity: 0.5;
}