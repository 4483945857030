.employee-settings {
  width: 100%;
  margin: 0 auto;
}

.employee-table {
  width: auto; /* テーブルの幅をウィンドウにフィット */
  min-width:70%;
  max-width: 90vw; /* テーブルがはみ出さないように */
  border-collapse: collapse;
  margin: 0 auto; /* 中央に配置 */
  table-layout: fixed;
  padding: 0 10px; /* 左右に少し余白 */
}

.employee-table th,
.employee-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
  white-space: nowrap;
  font-size: clamp(10px, 1.5vw, 16px); /* ウィンドウサイズに応じて文字サイズを調整 */
}

.employee-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.employee-table tr:hover {
  background-color: #f9f9f9;
}

.employee-table .setting-cell{
  width:50px;
}

button {
  padding: 8px 12px;
  margin:2px;
  border: none;
  background-color: #3498db;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: #2980b9;
}

.button-del {
  background-color: #e74c3c;
}

.button-del:hover {
  background-color: #c0392b;
}
