/* テーブル全体のスタイル */
table {
  width: auto;
  /* テーブルの幅をウィンドウにフィット */
  max-width: 90vw;
  /* テーブルがはみ出さないように */
  border-collapse: collapse;
  margin: 0 auto 15px auto;
  /* 中央に配置 */
  table-layout: fixed;
  padding: 0;
  /* 左右に少し余白 */
  border-collapse: separate;
  border-spacing: 0;
}

.shift-table>tbody>tr {
  height: 40px;
}


/* ヘッダーとセルの枠線 */
th,
td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
  white-space: nowrap;
  font-size: clamp(10px, 1.5vw, 16px);
  /* ウィンドウサイズに応じて文字サイズを調整 */
}

.shift-table thead {
  position: sticky;
  top: 50px;
  z-index: 2;
  background-color: #f9f9f9;
}

th:first-child,
td:first-child {
  width: 100px;
  /* 左端のセルの幅 */
  text-align: center;
  /* 名前を左揃え（必要に応じて） */
}

/* 時間表示用のセル */
.shift-time {
  position: relative;
  font-size: clamp(15px, 1.8vw, 20px);
  line-height: 1.2;
  text-align: center;
  background-color: var(--color1, #ffffff);
  /* デフォルトは1色 */
  background-image: var(--gradient, none);
  /* 2色目が有効な場合に適用 */
  padding: 1px;
}


.regular-shift {
  white-space: nowrap;
  /* 改行しない */
}

.custom-shift {
  /* 特殊シフト用に、折り返しはそのまま */
  white-space: pre-line;
}

.shift-content {
  position: relative;
  /* マークの配置基準 */
  display: inline-block;
  /* 必要に応じて調整 */
}

.note-marker {
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 0.8em;
}

/* テーブルヘッダーの背景色 */
th {
  background-color: #f2f2f2;
  /* ヘッダーの背景を薄いグレーに */
}

/* 各曜日の背景色 */
.sunday {
  background-color: #ffcccc;
  /* 日曜: 薄い赤 */
}

.saturday {
  background-color: #cce5ff;
  /* 土曜: 薄い青 */
}

.holiday {
  background-color: #ffcccc;
  /* 祝日: 日曜と同じ色 */
}

.date-cell {
  text-align: center;
  font-family: monospace;
  /* 等幅フォントで中央揃えを強調 */
  width: 30px;
  /* 必要に応じて幅を調整 */
}

/* 曜日セルと日付セルを区別 */
th.date-cell,
th.weekday-cell {
  width: 30px;
}

/* 共通の幅設定 */
.container {
  width: 100%;
  margin: 0 auto;
}

.highlight-row td:first-child {
  background-color: rgba(87, 221, 109, 0.158);
  /* 行の強調色 */
}

.highlight-column {
  background-color: rgba(87, 221, 109, 0.158);
  /* 列の強調色 */
}


.highlight-employee td:first-child {
  background-color: rgba(255, 223, 186, 0.8);
  /* 名前セルの背景色 */
}

.highlight-employee td {
  background-color: rgba(255, 239, 213, 0.5);
  /* 他のセルの背景色 */
}

th.highlight-employee {
  background-color: rgba(255, 223, 186);
  /* 名前セルの背景色 */
}


/* モーダルの背景 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* 半透明の黒背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* 高い値でモーダルを前面に */
}

/* 今日の列の一番上のセル */
.today-top {
  border-top: 3px solid red;
  border-left: 3px solid red;
  border-right: 3px solid red;
}

/* 今日の列の中間セル */
.today-column {
  border-left: 3px solid red;
  border-right: 3px solid red;
}

/* 今日の列の一番下のセル */
.today-bottom {
  border-bottom: 3px solid red;
  border-left: 3px solid red;
  border-right: 3px solid red;
}

/* モーダル本体 */
.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

/* モーダル内の閉じるボタン */
.modal button {
  margin-top: 15px;
  padding: 8px 16px;
  border: none;
  background-color: #3498db;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.modal button:hover {
  background-color: #2980b9;
}


/* 縦表示の今日行 - 日付列（左端） */
.today-transposed-date {
  border-top: 3px solid blue;
  border-left: 3px solid blue;
  border-bottom: 3px solid blue;
}

/* 縦表示の今日行 - 左端のセル */
.today-transposed-left {
  border-top: 3px solid blue;
  border-bottom: 3px solid blue;
}

/* 縦表示の今日行 - 中間のセル */
.today-transposed-middle {
  border-top: 3px solid blue;
  border-bottom: 3px solid blue;
}

/* 縦表示の今日行 - 右端のセル */
.today-transposed-right {
  border-top: 3px solid blue;
  border-right: 3px solid blue;
  border-bottom: 3px solid blue;
}

.tab-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.tab-buttons button {
  padding: 10px 20px;
  border: none;
  background: lightgray;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  transition: background 0.3s;
}

.tab-buttons button.active-tab {
  background: dodgerblue;
  color: white;
}

.date-modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-height: 70vh;
  overflow-y: auto;
  display: inline-block;
  /* 内容に応じてサイズを調整 */
  max-width: 90%;
  /* ウィンドウ幅の90%を超えないように */
  min-width: 300px;
  /* 最小幅を設定 */
}

.timenumtable {
  width: 80%;
}

.tab-content {
  max-width: clamp(40vw, 50vw, 60vw)
}

.under-staffed {
  background-color: #ffcccc;
  /* 赤っぽい背景 */
  color: #a80000;
  /* 文字色も少し濃い赤に */
  font-weight: bold;
}

@media screen and (max-width: 768px) {

  .shift-table {
    border-collapse: separate;
    border-spacing: 0;
  }

  .shift-table.transposed {
    overflow: auto;
    /* スクロールを有効化 */
    max-height: 80vh;
    /* テーブルの高さを制限 */
    max-width: 100%;
    /* テーブルの幅を制限 */
    position: relative;
    /* stickyの基準を設定 */
    padding: 0;
  }

  /* 行列入れ替え時だけ適用 */
  .shift-table.transposed td:first-child,
  .shift-table.transposed th:first-child {
    position: sticky;
    left: 0;
    /* 左側に固定 */
    z-index: 2;
    /* 他のセルより上に表示 */
    border-right: 1px solid #ccc;
    background-color: #f2f2f2;
  }

  .shift-table.transposed th {
    position: sticky;
    top: 0;
    /* 上側に固定 */
    z-index: 3;
    /* 列よりさらに上に表示 */

  }

  .shift-table.transposed th:first-child {
    z-index: 4;
    /* 左上隅のセルをさらに上に */

  }

  /* スクロールを有効化 */
  .shift-table-container {
    overflow: auto;
    /* スクロールを有効化 */
    max-height: 80vh;
    /* テーブルの高さを制限 */
    max-width: 99%;
    /* テーブルの幅を制限 */
    position: relative;
    /* stickyの基準を設定 */
  }

  .shift-table.jtransposed tr:first-child {
    background-color: #f2f2f2;
  }

  .shift-table.transposed td.sunday:first-child {
    background-color: #ffcccc !important;
    /* 日曜日の背景色 */
  }

  .shift-table.transposed td.saturday:first-child {
    background-color: #cce5ff !important;
    /* 土曜日の背景色 */
  }

  .shift-table.transposed td.holiday:first-child {
    background-color: #ffcccc !important;
    /* 祝日の背景色 */
  }

  .note-marker {
    position: absolute;
    /* font-size: 0.8em; */
  }

}

@media print {
  .no-print {
    display: none;
    /* 印刷時に非表示 */
  }
}