.settings-page {
    display: flex;
  }
  
  .settings-content {
    margin-left: 200px; /* サイドバーの幅に合わせる */
    padding: 20px;
    flex-grow: 1;
  }


  @media screen and (max-width: 768px) {
    .settings-page{
      display:flex;
    }
    .settings-content {
      margin: 0; /* サイドバーの幅に合わせる */
      margin-top:70px;
      width: 100vw;
      padding: 0;
      flex-grow: 1;
    }




  }