.user-settings {
    width: 100%;
}

.user-table {
    max-width: 70%;
}

.user-table .setting-cell {
    width: 30px;
}


@media screen and (max-width: 768px) {
    .user-table-wrapper {
        overflow: auto;
        /* スクロールを有効化 */
        max-height: 80vh;
        /* 高さの最大値 */
        max-width: 100%;
        /* 幅の最大値 */
        position: relative;
        /* stickyの基準を設定 */
    }


    .user-table {
        padding: 0;

        border-collapse: separate;
        border-spacing: 0;

    }

    .user-table thead {
        position: sticky;
        top: 0;
        z-index: 3;
    }

    .user-table td:first-child {
        position: sticky;
        left: 0;
        /* 左端に固定 */
        z-index: 2;
        /* 列ヘッダーの下 */
        background-color: #f9f9f9;
    }

    .user-table th:first-child {
        position: sticky;
        top: 0;
        /* 上端に固定 */
        left: 0;
        /* 左端に固定 */
        z-index: 5;
        /* 他の要素より高く設定 */
        background-color: #f9f9f9;
        /* 背景色を明示 */
    }


}