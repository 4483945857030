.LoginHistory{
    width:auto;
}

.LoginHistory .idinput{
    width: 30px;
}

.LoginHistory input{
    width:auto;
}

.LoginHistory table{
    width:auto;
    max-width:70%;
}

.searchoption{
    width:auto;
}

