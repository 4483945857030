/* App.css */

.body{
  min-height:100vh;
}

/* アプリ全体の基本スタイル */
.App {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
  
  background-color: #f9f9f9;
  padding: 50px 0 0 0;
}

/* コンテナとボタン */
.container {
  max-width: 100vw;
  margin: 0 auto;
}

.button-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.button-container button {
  padding: 8px 16px;
  margin: 0 5px;
  background-color: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.button-container button:hover {
  background-color: #1565c0;
}

h1 {
  font-size: 24px; /* 必要に応じて調整 */
  white-space: nowrap; /* 折り返しを防ぐ */
  overflow: hidden; /* 幅を超えた部分を隠す */
  text-overflow: ellipsis; /* 省略記号を表示 */
  max-width: 100%; /* ビューポートに収める */
  margin: 0 auto; /* 中央揃え */
  text-align: center; /* 中央揃え */
}