/* src/components/ShiftSymbolSettings.css */
.shift-symbol-settings {
  width: 100%;
  margin: 0 auto;
}

.shift-table {
  min-width: 70%;
}

.shift-table .setting-cell {
  width: 30px;
}

.shift-list {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.shift-list li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.shift-form {
  display: flex;
  gap: 10px;
}

.shift-form input {
  flex-grow: 1;
  padding: 8px;
  font-size: 14px;
}

.shift-form button {
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
}


@media screen and (max-width: 768px) {
  .shift-symbol-settings-container {
    overflow: auto;
    /* スクロールを有効化 */
    max-height: 80vh;
    /* 高さの最大値 */
    max-width: 100%;
    /* 幅の最大値 */
    position: relative;
    /* stickyの基準を設定 */
  }


  .shift-table {
    padding: 0;
  }

  .shift-table thead {
    position: sticky;
    top: 0;
    z-index: 3;
  }

  .shift-table td:first-child {
    position: sticky;
    left: 0;
    /* 左端に固定 */
    z-index: 2;
    /* 列ヘッダーの下 */
  }

  .shift-table th:first-child {
    position: sticky;
    top: 0; /* 上端に固定 */
    left: 0; /* 左端に固定 */
    z-index: 5; /* 他の要素より高く設定 */
    background-color: #f9f9f9; /* 背景色を明示 */
  }


}