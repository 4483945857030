/* src/components/Sidebar.css */

.sidebar {
    width: 200px;
    padding: 20px;
    background-color: #f5f5f5;
    border-right: 1px solid #ddd;
    position: fixed;
    top: 50px; /* ヘッダーの高さ分 */
    bottom: 0;
    overflow-y: auto;
  }
  
  .sidebar h3 {
    font-size: 1.2em;
    margin-bottom: 15px;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar ul li {
    margin-bottom: 10px;
  }
  
  .sidebar ul li a {
    text-decoration: none;
    color: #333;
  }
  
  .sidebar ul li a:hover {
    color: #1976d2;
  }
  

  /* モバイル向けのタブ表示 */
@media screen and (max-width: 768px) {
  .sidebar {
    width:100vw;
    height:60px;
    display: flex; /* 横並びにする */
    flex-direction: row;
    justify-content: space-around;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ddd;
    padding: 0; /* 上下の余白を追加 */
    overflow-x: auto; /* 横スクロール対応 */
    white-space: nowrap; /* テキスト折り返し防止 */
    font-size:small;
  }

  .sidebar div{
    padding:3px;
  }

.sidebar div:nth-child(even){
  border-left: solid black 1px;
}

  .sidebar h3{
    display:flex;
    margin:0;
  }

  .sidebar ul {
    display: flex; /* リストを横並び */
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none; /* デフォルトのマーカーを削除 */
  }

  .sidebar li {
    display:flex;
    margin: 0;
  }

  .sidebar ul li{
    margin: 0 3px;
  }

  .sidebar a {
    border: solid 1px;
    border-radius: 2px;
    display: inline-block;
    padding: 2px;
    text-align: center;
    text-decoration: none;
    color: #333;
    background-color: #f0f0f0;
    font-size: 14px;
  }

  .sidebar a:hover {
    background-color: #f0f0f0;
  }

  .sidebar a.active {
    background-color: #ddd;
    color: #000;
    font-weight: bold;
  }
}
