/* ShiftSidebar.css */

/* フロートタブのスタイル */
.float-tab {
  position: fixed;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 40px;
  height: 80px;
  background-color: #007bff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  border-radius: 8px 0 0 8px;
  cursor: pointer;
  transition: transform 0.3s ease;
  z-index:9;
}

.float-tab img {
  width: 24px;
  height: 24px;
}

;

.float-tab:hover {
  transform: translate(10px, -50%);
  /* マウスオーバーで少しせり出す */
}

/* サイドバーのスタイル */
.shift-sidebar {
  position: fixed;
  right: 0;
  top: 0;
  width: 250px;
  height: 100%;
  background-color: #f0f0f0;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 10;
}

.shift-sidebar.open {
  transform: translateX(0);
  /* openクラスが付くと画面内に表示 */
}

.close-btn {
  color: black;
  position: absolute;
  margin:0;
  padding:0;
  height:auto;
  top: 60px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* サイドバー内のシフトテーブルのスタイル */
.sidebar-shift-table {
  width: 99%;
  border-collapse: collapse;
  margin-top: 40px;
}

.sidebar-shift-table th,
.sidebar-shift-table td {
  padding: 5px;
  text-align: center;
  /* 水平方向の中央揃え */
  vertical-align: middle;
  /* 垂直方向の中央揃え */
  border: 1px solid #ddd;
  padding: 1px 0px;
}

.sidebar-shift-table th:nth-child(1),
.sidebar-shift-table td:nth-child(1) {
  width: 25%;
  /* ラベル列 */
}

.sidebar-shift-table th:nth-child(2),
.sidebar-shift-table td:nth-child(2) {
  width: 45%;
  /* 時間帯列 */
}

.sidebar-shift-table th:nth-child(3),
.sidebar-shift-table td:nth-child(3) {
  width: 30%;
  /* 勤務時間列 */
}


.sidebar-shift-table th {
  background-color: #007bff;
  color: white;
}

.sidebar-shift-table tbody tr {
  transition: background-color 0.3s ease;
}

.sidebar-shift-table tbody tr:hover {
  background-color: #f2f2f2;
}

@media print {
  .no-print {
    display: none; /* 印刷時に非表示 */
  }
}