.updatemodal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90vw;
    text-align: center;
    max-height:70vh;
    overflow-y:auto;
  }

  .updatemodal-text{
    text-align: left;
    font-size:15px;
  }