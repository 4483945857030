.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 50px); /* 微調整でスクロールバーを防止 */
    background-color: #f9f9f9;
    text-align: center;
  }
  
  .login-logo {
    margin-bottom: 40px;

  }

  .responsive-logo {
    width: 100%; /* 画面幅の50% */
    max-width: 50vw; /* 最大幅を制限 */
    height: auto; /* アスペクト比を維持 */
    transform: scale(1.5);
    transform-origin:center;
  }
  
  form {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 300px;
    text-align: center;
  }

  form > h2{
    margin:0px
  }

  form div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
  }

  label {
    font-weight: bold;
    margin-bottom: 5px;
    text-align: left;
    width: 100%; /* 左揃えしたい場合はこれを維持 */
  }
  
  input {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .login-button {
    width: 50%;
    padding: 10px;
    background: #3498db;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 1px;
  }
  
  .login-button:hover {
    background: #2980b9;
  }
  