.ActionHistory{
    width:auto;
}

.ActionHistory.idinput{
    width: 30px;
}

.ActionHistory input{
    width:auto;
}

.ActionHistory table{
    width:auto;
    max-width:70%;
}

.searchoption{
    width:auto;
}

