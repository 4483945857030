.bug-state-open {
    color: red;
    font-weight: bold;
    background-color:rgba(255, 0, 0, 0.2);
    border-radius: 10dvh;
    border-width: 1px;
    border-style: solid;
    padding:3px;
  }
  
  .bug-state-closed {
    color: green;
    font-weight: bold;
    background-color:rgba(0, 255, 0, 0.2);
    border-radius: 10dvh;
    border-width: 1px;
    border-style: solid;
    padding:3px;
  }
  