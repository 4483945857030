/* 全体のスタイル */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: lightskyblue;
  color: black;
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* 左側：ロゴとシフトリンク */
.header-left {
  display: flex;
  align-items: center;
}

.header-logo {
  font-size: 1.5em;
  cursor: pointer;
  margin-right: 20px;  /* ロゴとリンクの間のスペース */
}

.header-back-link {
  color: white;
  text-decoration: none;
}

.header-back-link:hover {
  text-decoration: underline;
}

/* 右側：設定とログアウトボタン */
.header-right {
  display: flex; /* 横並びにする */
  align-items: center; /* 縦の中央揃え */
  gap: 10px; /* ボタン間のスペースを適切に */
}

.header-settings-link {
  color: white;
  text-decoration: none;
  white-space: nowrap; /* 改行を防ぐ */
}

.header-settings-link:hover {
  text-decoration: underline;
}

.header-logout-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1em;
  white-space: nowrap; /* 改行を防ぐ */
}

.header-logout-button:hover {
  text-decoration: underline;
}

.header-username {
  color: white;
  font-size: 1em;
  white-space: nowrap; /* 改行を防ぐ */
  margin-right: 10px; /* ユーザー名と次のリンクの間にスペースを追加 */
}

.user-menu {
  position: relative;
  cursor: pointer;
  display:flex;
  flex-direction:row;
  align-items:center;
}

.header-username {
  font-weight: bold;
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  white-space: nowrap; /* 改行を防ぐ */
}

.dropdown-menu a,
.dropdown-menu button {
  text-decoration: none;
  color: black;
  padding: 8px;
  text-align: left;
  cursor: pointer;
  background: none;
  border: none;
  font-size:medium;
}

.dropdown-menu a:hover,
.dropdown-menu button:hover {
  background-color: #f2f2f2;
  border-radius: 4px;
}

.hamburger-icon {
  font-size:x-large;
  color:white;
  display:inline;
  padding-bottom:5px;
}

.new-update-icon {
  color: red;
  font-weight: bold;
  margin-left: 5px;
}

/* モバイル表示時の調整 */
@media screen and (max-width: 768px) {
  .header{
    padding:5px;
  }

  .header-back-link {
    display: none; /* モバイルで「シフト表示へ戻る」を非表示 */
  }

  .header-left {
    flex-direction: column; /* ロゴと他の要素を縦に並べる */
    align-items: center;
    text-align: center; /* 中央揃え */
  }

  .header-right {
    flex-wrap: nowrap; /* 必要に応じて折り返し */
    justify-content: center; /* 要素を中央揃え */
  }

  .header-username, .header-settings-link, .header-logout-button {
    font-size: 12px; /* モバイルでフォントサイズを縮小 */
    padding:0;
  }

}

@media print {
  .header {
    display: none; /* 印刷時に非表示 */
  }
}
