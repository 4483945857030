.passwordChange{
  display:flex;
  flex-direction:column;
  align-items: center;
}

.passwordChangeBox{
  display:flex;
  flex-direction:column;
  align-items:center;
}

.passwordChangeLabel{
  text-align:center;
}

.passwordChangeText{
  width:80%;
}

.passwordChangemodal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* 背景の暗幕 */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* 他の要素より前面に表示 */
    max-width: 100%;
  }
  
  .passwordChangemodal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 90%;
  }
  
  .passwordChangemodal-content button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background-color: #3498db;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .passwordChangemodal-content button:hover {
    background-color: #2980b9;
  }
  